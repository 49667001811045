import {StrictMode} from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import {BrowserRouter} from "react-router-dom";
import {initialiseMocks} from "./mocks/client";

function maybeStartMocks() {
  const searchParams = new URLSearchParams(window.location.search);
  const enableMocks = searchParams.get("mocks") == "true" || import.meta.env.VITE_FINAPP_MOCKS_ENABLED === "true";
  const mockAuth = import.meta.env.VITE_MOCK_AUTH === "true";
  if (enableMocks) return initialiseMocks({disablseAuth: mockAuth, onUnhandledRequest: "bypass"});
  return Promise.resolve();
}

(async function bootstrap() {
  await maybeStartMocks();

  ReactDOM.createRoot(document.getElementById("app")!).render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  );
})();
