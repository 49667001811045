import {StartOptions} from "msw";

export function initialiseMocks({
  disablseAuth,
  delay,
  ...options
}: {disablseAuth: boolean; delay?: number} & StartOptions) {
  return import("./browser")
    .then(({getWorker}) => getWorker(disablseAuth).start(options))
    .then(() => new Promise((resolve) => setTimeout(resolve, delay)));
}
